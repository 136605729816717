import React, { Component } from "react";
import { Route, Switch, withRouter } from "react-router";
import Layout from "./components/Layout";
import Quote from "./components/Quote/Quote";
import TalentManagement from "./components/TalentManagement/TalentManagement";

import "./main.scss";

export default class App extends Component {
  static displayName = App.name;

  render() {
    return (
      <Switch>
        <Route exact path="/:jobid/:token/:previewMode?">
          {({ match }) => (
            <Layout previewMode={match.params.previewMode}>
              <Quote />
            </Layout>
          )}
        </Route>
        {/* Additional routes with preview mode*/}
        <Route
          render={() => (
            <Layout>
              <Switch>
                <Route
                  exact
                  path="/talent-management/:projectId/:scheduleId/:token"
                  component={TalentManagement}
                />
                {/* Additional routes with no preview mode*/}
              </Switch>
            </Layout>
          )}
        />
      </Switch>
    );
  }
}
import axios from "axios";
import React, { createContext, useContext, useEffect, useState } from "react";
const initialState = {
  greetings: "",
  isCustomFontLoaded: false,
  isPreviewMode: false,
  isLoadingContent: true,
  shouldDisplayComponents: false
};

export const GlobalContext = createContext({});

export const GlobalContextProvider = ({ children }) => {
  const [state, setState] = useState(initialState);

  //create Set is Font loaded

  const setGreetings = (value) => {
    setState({
      ...state,
      greetings: value || "",
    });
  };

  const setIsCustomFontLoaded = (value) => {
    setState({
      ...state,
      isCustomFontLoaded: value,
    });
  };

  const setIsPreviewMode = (value) => {
    setState({
      ...state,
      isPreviewMode: value,
    });
  };

  const setIsLoadingContent = (value) => {
    setState({
      ...state,
      isLoadingContent: value,
    });
  };

  const setShouldDisplayComponents = (value) => {
    setState({
      ...state,
      shouldDisplayComponents: value,
    });
  };

  return (
    <GlobalContext.Provider
      value={{
        greetings: state.greetings,
        isCustomFontLoaded: state.isCustomFontLoaded,
        isPreviewMode: state.isPreviewMode,
        isLoadingContent: state.isLoadingContent,
        shouldDisplayComponents: state.shouldDisplayComponents,
        shouldDisplayComponents: state.shouldDisplayComponents,
        setIsCustomFontLoaded,
        setIsPreviewMode, 
        setIsLoadingContent, 
        setShouldDisplayComponents, 
        setGreetings
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export function useGlobalContext() {
  return useContext(GlobalContext);
}

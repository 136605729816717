import React, { useEffect, useState, Fragment } from "react";
import JobDescription from "./JobDescription";
import { useParams } from "react-router-dom";
import axios from "axios";
import Loader from "../Shared/Loader";
import GrandOpen from "../Shared/GrandOpen";
import "./quote.scss";
import QuotePhrase from "./QuotePhrase";
import JobName from "./JobName";
import Production from "./Production";
import Other from "./OtherCosts";
import QuoteBottom from "./QuoteBottom";
import AccountsDetailsDialog from "./AccountsDetailsDialog";
import _ from "lodash";
import Download from "./Download";
import { Skeleton } from "@mui/material";
import { JobApproved } from "./JobApproved";
import BillingInfo from "./BillingInfo";
import ThompsonSpencerLoader from "../Shared/ThompsonSpencerLoader";
import FontFaceObserver from "fontfaceobserver";
import { useGlobalContext } from "../../context/GlobalContext";
import DefaultHeader from "../Shared/DefaultHeader";

const Quote = () => {
  const { jobid, token, previewMode } = useParams();
  const {
    setIsPreviewMode,
    shouldDisplayComponents,
    setGreetings,
  } = useGlobalContext();
  const [job, setJob] = useState(null);
  const [user, setUser] = useState(null);
  const [renderProduction, setRenderProduction] = useState(false);
  const [renderOther, setRenderOther] = useState(false);
  const [openAccountsDetailsDialog, setOpenAccountsDetailsDialog] =
    useState(false);
  const [termsHtml, setTermsHtml] = useState("");
  const [emailsCc, setEmailsCc] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [jobConfirmed, setJobConfirmed] = useState(false);
  const [confirmationType, setConfirmationType] = useState(null);
  const [tempConfirmationType, setTempConfirmationType] = useState(null);

  // registereed company
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [company, setCompany] = useState(null);

  useEffect(() => {
    setIsPreviewMode(previewMode === "1" ? true : false)
  }, [])
  

  useEffect(() => {
    const _isPreviewMode = previewMode === "1" ? true : false;
    const validate = axios.get(
      `Quote/Validate/${jobid}/${token}?isPreviewMode=${_isPreviewMode}`
    );
    const getUser = axios.get(`Quote/GetUserByEmailForApprovals/${jobid}`);

    if (!job) {
      axios
        .all([validate, getUser])
        .then(
          axios.spread((validate, user) => {
            setJob(validate.data.job);
            setIsReadOnly(validate.data.job.status === 'approved')
            setTermsHtml(validate.data.termsHtml);
            checkCategories(validate.data.job);
            checkEmailsCc(validate.data.job.contact.emailsCc);
            getCompanyContacts(validate.data.job.companyId);
            // getCompany(validate.data.job.companyId);
            setCompany(validate.data.job.company)
            setTimeout(() => {
              setGreetings(`Hi ${validate.data.job.contact.firstName}`);
            }, 1500);
            setUser(user.data);

          })
        )
        .catch((err) => {
          console.log(err);
          alert("Unable to validate token");
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [job]);

  const getCompanyContacts = (companyId) => {
    axios
      .get(`Quote/FetchContactsByCompany/${companyId}`)
      .then(({ data }) => {
        setContacts(data);
      })
      .catch((err) => {
        setContacts([]);
      });
  };

  const checkEmailsCc = (emailsString) => {
    if (emailsString) {
      const emailsArray = emailsString.split(";");
      if (emailsArray && emailsArray.length > 0) {
        setEmailsCc(emailsArray);
      } else {
        setEmailsCc([]);
      }
    }
  };

  const checkCategories = (job) => {
    let hasProduction = false;
    const otherCategoryGuid = job?.jobCategories
      .filter((c) => c.type === "Other")

    job.jobLines.every((jl) => {
      if (jl.category !== "Other" && !hasProduction) {
        hasProduction = true;
      }
      if (hasProduction) {
        return false;
      }
      return true;
    });

    if (hasProduction) {
      setRenderProduction(true);
    }
    if (otherCategoryGuid.length > 0) {
      setRenderOther(true);
    }
  };

  const handleCloseAccountsDetails = () => {
    setOpenAccountsDetailsDialog(false);
    setTempConfirmationType(null)
  };

  const createAccountsDetailAndConfirmJob = (confirmationType) => {
    setOpenAccountsDetailsDialog(true);
    setTempConfirmationType(confirmationType)
  };

  const handleSaveAccountsDetails = async (accountsDetails) => {
    axios
      .post(
        `Quote/UpdateAccountsDetails/${job.companyId}?emails=${emailsCc.join(
          ";"
        )}`,
        accountsDetails
      )
      .then(({ data }) => {
        if (data) {
          let jobClone = _.cloneDeep(job);
          jobClone.company.primaryAccountsEmail = accountsDetails.emailAddress;
          jobClone.company.accountsPhone = accountsDetails.phoneNumber;
          jobClone.company.accountsEmailsCc = emailsCc.join(";");
          setJob(jobClone);
          if (tempConfirmationType) {
            setConfirmationType(tempConfirmationType)
          }
        }
      });
    handleCloseAccountsDetails();
  };

  useEffect(() => {
    if (jobConfirmed) {
      window.scrollTo(0, 0);
    }
  }, [jobConfirmed]);


  return (
    <div>
      
      {openAccountsDetailsDialog && (
        <AccountsDetailsDialog
          open={openAccountsDetailsDialog}
          handleCloseAccountsDetails={handleCloseAccountsDetails}
          handleSaveAccountsDetails={handleSaveAccountsDetails}
          contactAccounts={job.company}
          contact={job.contact}
          contacts={contacts}
          companyId={job.companyId}
          emailsCc={emailsCc}
          setEmailsCc={setEmailsCc}
        />
      )}
      {previewMode && job == null && !shouldDisplayComponents && (
        <div>
          <Skeleton height={100} />
          <Skeleton />
          <Skeleton />
          <Skeleton height={100} />
          <Skeleton />
          <Skeleton />
          <Skeleton height={100} />
        </div>
      )}
      {((previewMode && job) || shouldDisplayComponents) && company && (
        <Fragment>
          {(jobConfirmed && user) ? (
            <Fragment>
              <JobApproved user={user} organisationId={job?.organisationId} />
            </Fragment>
          ) : (
            <Fragment>
              <DefaultHeader />
              <BillingInfo job={job} contact={job.contact}/>
              <JobName job={job} />
              {/* <QuotePhrase /> */}
              <JobDescription jobDescription={job.jobDescription} />
              {renderProduction && <Production job={job} company={company} />}
              {renderOther && <Other job={job} />}
              <QuoteBottom
                job={job}
                termsHtml={termsHtml}
                setOpenAccountsDetailsDialog={setOpenAccountsDetailsDialog}
                createAccountsDetailAndConfirmJob={createAccountsDetailAndConfirmJob}
                previewMode={previewMode}
                setJobConfirmed={setJobConfirmed}
                isReadOnly={isReadOnly}
                confirmationType={confirmationType}
                company={company}
                setJob={setJob}
                setConfirmationType={setConfirmationType}
              />
              {/* <Download job={job} /> */}
            </Fragment>
          )}
        </Fragment>
      )}
    </div>
  );
};

export default Quote;

import React, { useEffect, useState } from "react";
import {
    Dialog,
    AppBar,
    Slide,
    Toolbar,
    IconButton,
    DialogTitle,
    DialogContent,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import "./shared.scss";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const DialogWithDriveFileContent = (props) => {
    let {
        isOpen,
        handleClose,
        driveId,
        title
    } = props;

    return (
        <Dialog
            open={isOpen}
            onClose={handleClose}
            TransitionComponent={Transition}
            onBackdropClick={(e) => e.preventDefault()}
            classes={{ paper: "drive-file-dialog" }}
        >
            <DialogTitle className="use-main-font dialog-with-html-content-title" onClose={props.handleClose}>
                <span className="dialog-with-html-content-title-text use-main-font" >{title}</span>
                <span className="dialog-with-html-content-close-button use-main-font">
                    <IconButton
                        size="small"
                        onClick={() => handleClose()}
                    >
                        <CloseIcon className="close-contract-modal-icon" />
                    </IconButton>
                </span>
                <hr style={{ paddingBottom: "0px", marginBottom: "0px", paddingTop: "0px", marginTop: "0px" }} />
            </DialogTitle>
            <DialogContent>
                <div className="use-main-font drive-file-container">
                    <iframe
                        key={"drive-document"}
                        title={title}
                        src={`https://drive.google.com/file/d/${driveId}/preview`}
                        width={"100%"}
                        height={"100%"}
                        className={"iframe-scale-height"}
                    >
                    </iframe>
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default DialogWithDriveFileContent;
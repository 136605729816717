import React, { useState, useEffect, Fragment } from "react";
import JobDescription from "./JobDescription";
import { getCurrencies } from "../../utils/general";

const OtherCosts = (props) => {
  const { job } = props;

  const getOtherCostsRow = () => {
    const symbol = getCurrencies().find((c) => c.value === job.currency).symbol;
    // eslint-disable-next-line array-callback-return
    const categoriesGuid = job?.jobCategories
      .filter((c) => c.type === "Other")
      .map((c) => c.guid);
    return job.jobLines
      .filter((jl) => categoriesGuid.includes(jl.jobCategoryGuid))
      .map((jl) => {
        return (
          <tr>
            <td>{jl.name}</td>
            <td>{jl.description}</td>
            <td>{jl.quantity}</td>
            <td>
              {jl.rateType === "Open Budget" ? jl.exchangedTotal : jl.sellPrice}
            </td>
            <td align="right">{`${symbol || "$"}${jl.exchangedTotal}`}</td>
          </tr>
        );
      });
  };

  return (
    <div id="quote-sections">
      <table className="magnesium-table use-main-font">
        <thead>
          <tr>
            <th colSpan={5} align="left">
              Other Costs
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Item</td>
            <td>Description</td>
            <td>Quantity</td>
            <td>Cost</td>
            <td align="right">Total</td>
          </tr>
          {getOtherCostsRow()}
        </tbody>
      </table>
    </div>
  );
};

export default OtherCosts;

import React, { useState, useEffect } from "react";
import Close from "@mui/icons-material/Close";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  CircularProgress,
} from "@mui/material";

const PoNumberDialog = (props) => {
  const { poNumber, setPoNumber, confirmJob, onClose } = props;

  const [loading, setLoading] = useState(false);

  return (
    <div>
      <Dialog
        open={props.open}
        aria-labelledby="form-dialog-title-ponumber"
        fullWidth
        onClose={(e, reason) => {
          if (reason !== "backdropClick") {
            onClose();
          }
        }}
      >
        <DialogTitle
          id="form-dialog-title-ponumber"
          className="sales-dialog-title use-main-font"
        >
          Purchase Order
          <IconButton
            aria-label="close"
            onClick={props.onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
            disabled={loading}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <TextField
            className="use-main-font"
            label="PO Number"
            placeholder="Enter PO Number here"
            variant="outlined"
            value={poNumber}
            onChange={(e) => {
              setPoNumber(e.target.value);
            }}
            fullWidth
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
            disabled={loading}
          />
        </DialogContent>
        <DialogActions>
          {loading ? (
            <div style={{pading: 10}}>
              <CircularProgress />
            </div>
          ) : (
            <React.Fragment>
              <button
                className="use-main-font quote-button"
                onClick={() => {
                  setLoading(true);
                  confirmJob();
                }}
              >
                I will provide later
              </button>
              <button
                className="use-main-font quote-button"
                disabled={
                  poNumber === null ||
                  poNumber === undefined ||
                  poNumber.trim() === ""
                }
                onClick={() => {
                  setLoading(true);
                  confirmJob();
                }}
              >
                Approve
              </button>
            </React.Fragment>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PoNumberDialog;

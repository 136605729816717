import React, { Fragment, useEffect, useState } from "react";
import { Slide } from "@mui/material";
import { useGlobalContext } from "../../context/GlobalContext";

const GrandOpen = (props) => {
  const { loading, } = props;
  const {shouldDisplayComponents, setShouldDisplayComponents} = useGlobalContext()
  const [loaded, setloaded] = useState(false);
  const [slideOut, setslideOut] = useState(false);


  useEffect(() => {
    if (!loading) {
      setTimeout(() => {
        setloaded(true);
      }, 100);
    }
    if (loaded && !shouldDisplayComponents) {
      setslideOut(true);
    }
    if (slideOut) {
      setTimeout(() => {
        setShouldDisplayComponents(true);
      }, 1500);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, loaded, shouldDisplayComponents, slideOut]);
  return (
    <Fragment>
      <div>
        <Slide
          in={!slideOut}
          mountOnEnter
          unmountOnExit
          timeout={{ appear: 0, exit: 1500 }}
          direction="left"
        >
          <div className="right-black-panel"></div>
        </Slide>
      </div>
      <div className="black-panel-div-overflow">
        <Slide
          in={!slideOut}
          mountOnEnter
          unmountOnExit
          timeout={{ appear: 0, exit: 1500 }}
          direction="right"
        >
          <div className="left-black-panel"></div>
        </Slide>
      </div>
    </Fragment>
  );
};

export default GrandOpen;

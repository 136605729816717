import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { GetMonthAsString } from "../../utils/dateUtils";
import { addMonths, format } from "date-fns";

const BillingInfo = (props) => {
  const { job, contact } = props;
  const getDate = (job) => {
    var date = job.approved !== null ? new Date(job?.approved) : new Date();
    return (
      <div className={"billing-date"}>
        {date.getDate()} {GetMonthAsString(date.getMonth())},{" "}
        {date.getFullYear()}
      </div>
    );
  };

  const getRetainerStartDate = (job) => {
    var endDate = new Date(job.retainerEndDate);
    var startDate = addMonths(endDate, -job.retainerDuration);

    return startDate;
  };

  const getBillingSection = (company) => {
    var address = company?.address;
    var isNzme = company?.companySize === "NZME";
    if (address && !isNzme) {
      var addressArr = address.split(",");
      return (
        <div className="billing-container">
          <div className={"quote-magrin-line"}>{company.registeredName}</div>
          {addressArr.map((a) => (
            <div className={"quote-magrin-line"}>{a}</div>
          ))}
        </div>
      );
    } else if (isNzme) {
      return (
        <div className="billing-container">
          <div className={"quote-magrin-line"}>{company.name}</div>
        </div>
      );
    }
  };

  return (
    <Grid container className="quote-billing-info-root">
      {/* row 1 */}
      <Grid item xs={5} md={5} lg={5} paddingBottom={2} paddingTop={3}>
        {getDate(job)}
      </Grid>
      <Grid item xs={4} md={4} lg={4} paddingBottom={2} paddingTop={3}>
        <div style={{ display: "grid" }}>
          <span>L1, 3 Lorne Street,</span>
          <span>Auckland Central</span>
        </div>
      </Grid>
      <Grid item xs={12} md={3} lg={3} paddingBottom={2} paddingTop={3}>
        <div className="billing-container">
          <div className="quote-magrin-line">{`${contact.firstName} ${contact.lastName}`}</div>
          <div className="quote-magrin-line">{contact.emailAddress}</div>
          <div className="quote-magrin-line">{contact.phoneNumber}</div>
        </div>
      </Grid>
      {job.isRetainer ? (
        <>
          {/* row 2 */}
          <Grid
            item
            xs={4}
            md={4}
            lg={4}
            className={"billing-header-block"}
            paddingBottom={2}
          >
            BILLED TO:
          </Grid>
          <Grid
            item
            xs={2}
            md={2}
            lg={2}
            className={"billing-header-block"}
            paddingBottom={2}
          >
            QUOTE NO:
          </Grid>
          <Grid
            item
            xs={2}
            md={2}
            lg={2}
            className={"billing-header-block"}
            paddingBottom={2}
          >
            {job.company.companySize !== "NZME" ? "PO NO:" : ""}
          </Grid>
          <Grid
            item
            xs={2}
            md={2}
            lg={2}
            className={"billing-header-block"}
            paddingBottom={2}
          >
            RETAINER START DATE
          </Grid>
          <Grid
            item
            xs={2}
            md={2}
            lg={2}
            className={"billing-header-block"}
            paddingBottom={2}
          >
            RETAINER DURATION
          </Grid>
          {/* row 3 */}
          <Grid item xs={4} md={4} lg={4} className={"header-blocks"}>
            {getBillingSection(job?.company)}
          </Grid>
          <Grid item xs={2} md={2} lg={2} className={"header-blocks"}>
            {job?.id}
          </Grid>
          <Grid item xs={2} md={2} lg={2} className={"header-blocks"}>
            {job.company.companySize !== "NZME" ? job?.poNumber : ""}
          </Grid>
          <Grid item xs={2} md={2} lg={2} className={"header-blocks"}>
            {`${format(getRetainerStartDate(job), "dd/MM/yyyy")}`}
          </Grid>
          <Grid item xs={2} md={2} lg={2} className={"header-blocks"}>
            {`${job.retainerDuration} months`}
          </Grid>
        </>
      ) : (
        <>
          <Grid
            item
            xs={5}
            md={5}
            lg={5}
            className={"billing-header-block"}
            paddingBottom={2}
          >
            BILLED TO:
          </Grid>
          <Grid
            item
            xs={4}
            md={4}
            lg={4}
            className={"billing-header-block"}
            paddingBottom={2}
          >
            QUOTE NO:
          </Grid>
          <Grid
            item
            xs={3}
            md={3}
            lg={3}
            className={"billing-header-block"}
            paddingBottom={2}
          >
            {job.company.companySize !== "NZME" ? "PO NO:" : ""}
          </Grid>
          {/* row 3 */}
          <Grid item xs={5} md={5} lg={5} className={"header-blocks"}>
            {getBillingSection(job?.company)}
          </Grid>
          <Grid item xs={4} md={4} lg={4} className={"header-blocks"}>
            {job?.id}
          </Grid>
          <Grid item xs={3} md={3} lg={3} className={"header-blocks"}>
            {job.company.companySize !== "NZME" ? job?.poNumber : ""}
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default BillingInfo;

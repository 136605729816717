import React, { useEffect, useState } from "react";
import {
    Dialog,
    AppBar,
    Slide,
    Toolbar,
    IconButton,
    DialogTitle,
    DialogContent,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const DialogWithHtmlContent = (props) => {
    let {
        isOpen,
        handleClose,
        htmlContent
    } = props;

    return (
        <Dialog
            open={isOpen}
            onClose={handleClose}
            TransitionComponent={Transition}
            maxWidth={"md"}
            onBackdropClick={(e) => e.preventDefault()}
        >
            <DialogTitle className="use-main-font dialog-with-html-content-title" onClose={props.handleClose}>
                <span className="dialog-with-html-content-title-text use-main-font" >Terms and Conditions</span>
                <span className="dialog-with-html-content-close-button use-main-font">
                    <IconButton
                        size="small"
                        onClick={() => handleClose()}
                    >
                        <CloseIcon className="close-contract-modal-icon" />
                    </IconButton>
                </span>
                <hr style={{ paddingBottom: "0px", marginBottom: "0px", paddingTop: "0px", marginTop: "0px" }}/>
            </DialogTitle>
            <DialogContent>
                <span id="html-container" className="use-main-font" dangerouslySetInnerHTML={{ __html: htmlContent }} />
            </DialogContent>
        </Dialog>
    )
}

export default DialogWithHtmlContent;
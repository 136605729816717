import React from "react";

const JobDescription = (props) => {
  const { jobDescription } = props;
  return (
    <div id="quote-sections">
      <table className="magnesium-table">
        <thead>
          <tr>
            <th align="left"><b>PROJECT DETAILS</b></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div dangerouslySetInnerHTML={{ __html: jobDescription }} />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default JobDescription;

import React from "react";
import "./influencersList.scss";
import { Typography } from "@mui/material";
import InfluencerDetails from "./InfluencerDetails";
const InfluencersList = ({ influencers, handleStatusChange }) => {
  return (
    <div className="talent-management-influencer-list-root">
      <div className="talent-management-influencer-list-wrapper">
        <div className="talent-management-influencer-list-container talent-management-influencer-list-header">
          <Typography variant="subtitle2">Name / Account</Typography>
          <Typography variant="subtitle2">Platform</Typography>
          <Typography variant="subtitle2">Bio</Typography>
        </div>
        <div>
          <Typography variant="subtitle2">Status</Typography>
        </div>
      </div>

      {influencers?.map((influencer, index) => (
        <div key={index}>
          <InfluencerDetails influencer={influencer} handleStatusChange={handleStatusChange}/>
        </div>
      ))}
    </div>
  );
};

export default InfluencersList;

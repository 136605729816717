import { Skeleton } from "@mui/material";
import React from "react";

const LoadingSkeleton = () => {
  return (
    <div>
      <Skeleton height={100} />
      <Skeleton />
      <Skeleton />
      <Skeleton height={100} />
      <Skeleton />
      <Skeleton />
      <Skeleton height={100} />
    </div>
  );
};

export default LoadingSkeleton;

import React, { useEffect, useState } from "react";
import "./talentManagement.scss";
import { useParams } from "react-router-dom";
import { useGlobalContext } from "../../context/GlobalContext";
import axios from "axios";
import LoadingSkeleton from "../Shared/LoadingSkeleton";

import ClientDetails from "./ClientDetails";
import InfluencersList from "./InfluencersList";
import DefaultHeader from "../Shared/DefaultHeader";
const TalentManagement = () => {
  const { projectId, token, scheduleId } = useParams();
  const {
    isPreviewMode,
    shouldDisplayComponents,
    setGreetings,
  } = useGlobalContext();

  const [project, setProject] = useState(null);
  const [influencers, setInfluencers] = useState(null);

  useEffect(() => {
    setTimeout(() => {
      setGreetings(`Getting things ready`);
    }, 1500);
  }, []);

  useEffect(() => {
    if (!influencers) {
      fetchProjectDetails();
    }
  }, [influencers]);

  const fetchProjectDetails = () => {
    axios
      .get(
        `Project/ValidateTalentManagement/${projectId}/${scheduleId}/${token}`
      )
      .then(({ data }) => {
        setProject(data.project);
        setInfluencers(data.influencers);
      })
      .catch((err) => {
        alert("Unable to validate token");
      });
  };

  const handleStatusChange = (status, scheduleItemIdList) => {
    axios
      .post(
        `Project/UpdateInfluencerApprovalStatus/${projectId}/${scheduleId}/${status}/${token}`, 
        scheduleItemIdList
      )
      .then(({ data }) => {
        console.log(data)
        if (data?.length > 0) {
          setInfluencers(
            influencers.map((influencer) => {
              const matchingInfluencer = data.find(
                (infl) => infl.scheduleItemId === influencer.scheduleItemId
              );
              return matchingInfluencer ? matchingInfluencer : influencer;
            })
          );
        } else {
          alert("Sorry! Something Went Wrong.");
        }
      });
  };

  const handleUpdateAllStatuses = (status) => {
    handleStatusChange(status, influencers.map(i => i.scheduleItemId))
  }

  return (
    <div>
      {isPreviewMode && project == null && !shouldDisplayComponents && (
        <LoadingSkeleton />
      )}
      {(isPreviewMode && influencers) ||
        (shouldDisplayComponents && project && (
          <div className="talent-management-main-container">
            <DefaultHeader />
            <ClientDetails project={project} handleUpdateAllStatuses={handleUpdateAllStatuses}/>
            <InfluencersList influencers={influencers} handleStatusChange={handleStatusChange}/>
          </div>
        ))}
    </div>
  );
};

export default TalentManagement;
